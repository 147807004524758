import React, { useState, useEffect } from "react";
import "./FAQ.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AOS from "aos";
import "aos/dist/aos.css";
const FAQ = () => {
  const [expanded, setExpanded] = React.useState(0);

  const [backcolor, setbackcolor] = useState(0);
  const [backcolor1, setbackcolor1] = useState(0);
  const [backcolor2, setbackcolor2] = useState(0);
  const [backcolor3, setbackcolor3] = useState(0);
  const [backcolor4, setbackcolor4] = useState(0);

  const [changeColor, setChangeColor] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setbackcolor(1);
    } else {
      setbackcolor(0);

      setChangeColor("");
    }
  };

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setbackcolor1(1);
    } else {
      setbackcolor1(0);

      setChangeColor("");
    }
  };

  const handleChange3 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setbackcolor2(1);
    } else {
      setbackcolor2(0);

      setChangeColor("");
    }
  };

  const handleChange4 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setbackcolor3(1);
    } else {
      setbackcolor3(0);

      setChangeColor("");
    }
  };

  const handleChange5 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setbackcolor4(1);
    } else {
      setbackcolor4(0);

      setChangeColor("");
    }
  };

  console.log("jidfkjdsafi", expanded);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div
      data-aos="fade-left"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
      data-aos-delay="100"
      className="main_faq"
      id="123"
    >
      <div
        className="accordion_flex"
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        data-aos-delay="100"
      >
        <div data-aos="zoom-out" className="faq_title">
          FAQs
        </div>

        <Accordion
          className="acordianone"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel1" ? (
                <CloseIcon
                  className="icon"
                  style={{
                    backgroundColor: `${backcolor === 1 ? "black" : ""}`,
                  }}
                />
              ) : (
                <AddIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor ? "black" : ""}` }}
                />
              )
            }
            aria-controls="panel1bh-content"
            style={{ backgroundColor: `${changeColor == 1 ? "#fff3e9" : ""}` }}
            id="panel1bh-header"
            onClick={() => setChangeColor(1)}
          >
            <Typography
              className="faq_number"
              sx={{ width: "6%", flexShrink: 0 }}
            >
              01
            </Typography>
            <Typography
              className="faq_question"
              sx={{ color: "text.secondary" }}
            >
              How does the payment work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The payment will be made through the application in a totally
              secure way.You can make several requests for the same time to
              different Paladars, but the money will only come out of your
              account when the first host accepts it and all the other requests
              will be eliminated. Afterwards, the money will stay with us until
              a few days after your experience and you will be refunded if
              anything goes wrong.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange2("panel2")}
          // changeStyle={setStyle()}
        >
          <AccordionSummary
            // expandIcon={<AddIcon className="icon" />}
            expandIcon={
              expanded === "panel2" ? (
                <CloseIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor1 ? "black" : ""}` }}
                />
              ) : (
                <AddIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor1 ? "black" : ""}` }}
                />
              )
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            style={{ backgroundColor: `${changeColor == 2 ? "#fff3e9" : ""}` }}
            onClick={() => setChangeColor(2)}
          >
            <Typography
              className="faq_number"
              sx={{ width: "6%", flexShrink: 0 }}
            >
              02
            </Typography>
            <Typography
              className="faq_question"
              sx={{ color: "text.secondary" }}
            >
              Is the mobile app secure?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes. All the hosts with the “certified” badge have already been previously guaranteed by us.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange3("panel3")}
        >
          <AccordionSummary
            // expandIcon={<AddIcon className="icon" />}
            expandIcon={
              expanded === "panel3" ? (
                <CloseIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor2 ? "black" : ""}` }}
                />
              ) : (
                <AddIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor2 ? "black" : ""}` }}
                />
              )
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            style={{ backgroundColor: `${changeColor == 3 ? "#fff3e9" : ""}` }}
            onClick={() => setChangeColor(3)}
          >
            <Typography
              className="faq_number"
              sx={{ width: "6%", flexShrink: 0 }}
            >
              03
            </Typography>
            <Typography
              className="faq_question"
              sx={{ color: "text.secondary" }}
            >
              I have multiple allergies, how does the host know?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Don't worry, on your profile you'll have the possibility to add them and, when you make a request for an experience, the host will be notified.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          className="faq_question4"
          onChange={handleChange4("panel4")}
        >
          <AccordionSummary
            // expandIcon={<AddIcon className="icon" />}
            expandIcon={
              expanded === "panel4" ? (
                <CloseIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor3 ? "black" : ""}` }}
                />
              ) : (
                <AddIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor3 ? "black" : ""}` }}
                />
              )
            }
            aria-controls="panel3bh-content"
            id="panel4bh-header"
            style={{ backgroundColor: `${changeColor == 4 ? "#fff3e9" : ""}` }}
            onClick={() => setChangeColor(4)}
          >
            <Typography
              className="faq_number"
              sx={{ width: "6%", flexShrink: 0 }}
            >
              04
            </Typography>
            <Typography
              className="faq_question"
              sx={{ color: "text.secondary" }}
            >
              I am vegetarian or vegan. Are there any Paladares for me?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Of course! The experience of having a meal at a Paladar is for everyone! All Paladares with vegetarian or vegan badges will be the right ones for you.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange5("panel5")}
        >
          <AccordionSummary
            // expandIcon={<AddIcon className="icon" />}
            expandIcon={
              expanded === "panel5" ? (
                <CloseIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor4 ? "black" : ""}` }}
                />
              ) : (
                <AddIcon
                  className="icon"
                  style={{ backgroundColor: `${backcolor4 ? "black" : ""}` }}
                />
              )
            }
            aria-controls="panel3bh-content"
            id="panel5bh-header"
            style={{ backgroundColor: `${changeColor == 5 ? "#fff3e9" : ""}` }}
            onClick={() => setChangeColor(5)}
          >
            <Typography
              className="faq_number"
              sx={{ width: "6%", flexShrink: 0 }}
            >
              05
            </Typography>
            <Typography
              className="faq_question"
              sx={{ color: "text.secondary" }}
            >
              Could there be moments of embarrassment with the host?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No! Don't forget that the hosts are also people like you, who like to meet new people and different cultures. But, if you are afraid, you can always have the experience with other groups of travelers.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
