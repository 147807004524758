import React from "react";
import "./Navbar.scss";
import logo from "../Images/footerlogo.svg";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbar = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="mui-toggle"
    >
      <div className="close" onClick={toggleDrawer(anchor, true)}>
        <CloseIcon className="icons" />
      </div>

      <div className="navbar__container">
        <a style={{ color: "black", textDecoration: "none" }} href="#Home">
          <div className="items"> Home </div>
        </a>
        <a style={{ color: "black", textDecoration: "none" }} href="#Prob">
          <div className="items"> The Problem </div>
        </a>
        <a style={{ color: "black", textDecoration: "none" }} href="#Solu">
          <div className="items"> Our Solution </div>
        </a>
        <a style={{ color: "black", textDecoration: "none" }} href="#Hist">
          <div className="items"> The History of Paladares </div>
        </a>
        <a style={{ color: "black", textDecoration: "none" }} href="#Work">
          <div className="items"> How it Works </div>
        </a>
        <a style={{ color: "black", textDecoration: "none" }} href="#Benf">
          <div className="items"> Benefits </div>
        </a>
        <a style={{ color: "black", textDecoration: "none" }} href="#123">
          <div className="items"> FAQ </div>
        </a>
        <a style={{ color: "black", textDecoration: "none" }} href="#Subs">
          <div className="items"> Subscription </div>
        </a>
      </div>
    </Box>
  );

  return (
    <>
      <div className="main_navbar fixed-top">
        <div className="nav_logo">
          <button
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <img src={logo} className="mart logo_img" />
          </button>
        </div>

        <div className="nav_text" style={{ maxWidth: "930px" }}>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#Home">
              {" "}
              Home{" "}
            </a>
          </div>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#Prob">
              {" "}
              The Problem
            </a>{" "}
          </div>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#Solu">
              {" "}
              Our Solution{" "}
            </a>{" "}
          </div>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#Hist">
              {" "}
              The History of Paladares{" "}
            </a>{" "}
          </div>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#Work">
              {" "}
              How it Works{" "}
            </a>{" "}
          </div>
          <div className="hover-underline-animation">
            {" "}
            <a style={{ color: "black", textDecoration: "none" }} href="#Benf">
              {" "}
              Benefits{" "}
            </a>{" "}
          </div>
          <div className="hover-underline-animation">
            {" "}
            <a style={{ color: "black", textDecoration: "none" }} href="#123">
              FAQ{" "}
            </a>
          </div>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#Subs">
              {" "}
              Subscription{" "}
            </a>{" "}
          </div>
        </div>

        <div className="nav_btn">
          {" "}
          <a style={{ color: "white", textDecoration: "none" }} href="/host">
            <button className="nav_button"> Be a Paladar Host</button>{" "}
          </a>{" "}
        </div>

        <div className="ham_nav">
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <div className="burger" onClick={toggleDrawer(anchor, true)}>
                <div id="menu" class="menu_color">
                  <MenuIcon />
                </div>
              </div>

              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default Navbar;
