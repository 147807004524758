import React, { useEffect } from "react";
import "./Solution.scss";
import sol from "../Images/solution.svg";
import logo from "../Images/sollogo.svg";
import circle from "../Images/circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Solution = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div className="sol_centre" id="Solu">
      <div className="main_sol">
        <img data-aos="fade-right" className="sol_img" src={sol} />

        <div data-aos="fade-left" className="sol_text">
          <div className="sol_logo">
            <img src={logo} />
          </div>

          <div className="sol_title">Our Solution</div>

          <p className="sol_para">
            There is a solution for travelers who, like you, are not satisfied
            with this mass way of travelling. With the Paladar application it
            has never been so easy to meet the local population (not even before
            the low-cost airlines!). We will be the bridge between you and the
            locals who like to cook and meet people from all over the world,
            through a simple and totally safe mobile application.
            <span style={{ color: "#518855" }}>
              There's no better way to get to know a culture than to walk into
              their house and sit down at the table with them.
            </span>
            The Paladar application is a network for sharing meals in the locals
            private homes – designated in the application by Paladares - among
            those who are willing to be hosts and the travellers who are looking
            for and authentic experience of tourism, interacting with the local
            population and learning about their culture.{" "}
          </p>

          <div className="sol_circle">
            <img src={circle} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
