import React, { useEffect } from "react";
import "./Subscription.scss";
import back from "../Images/sub_back.svg";
import up from "../Images/up.svg";
import down from "../Images/down.svg";
import circle from "../Images/circle.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AOS from "aos";

const Subsription = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="flex_n" id="Subs">
      <div className="z_in">
        <div className="main_sub">
          <div className="relate">
            <img src={circle} className="circlea" />
            <div className="sub_img_sec">
              <img className="up" src={up} />
              <img className="down" src={down} />
            </div>

            {/* <img className='back' src={back}/> */}
            <img src={circle} className="circlea" />

            <div data-aos="fade-right" className="sub_text">
              <div className="sub_title">Subscription</div>

              <div data-aos="fade-left" className="sub_para">
                Get monthly updates so you dont miss any oportunity to have an
                awesome experience anymore
              </div>
            </div>

            <div className="sub_input">
              <div className="input_bg">
                <div data-aos="fade-right" className="input_start">
                  <input
                    className="input_t"
                    type="email"
                    placeholder=" Your Email address"
                  />
                </div>

                <button data-aos="fade-left" className="sub_btn">
                  Get Started <ArrowForwardIcon className="arrow" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="back">
          <img src={back} />
        </div>
      </div>
    </div>
  );
};

export default Subsription;
