import React from "react";

import groups from "../Images/paris.svg";
import buttonimg from "../../Page1/Images/buttonimg.svg";
import google from "../../../Container/Page1/Images/google.svg";
import apple from "../../../Container/Page1/Images/apple.svg";
import "./Hostmain.css";

export default function Hostmain() {
  return (
    <>
      {/* div 1 */}
      <div className="maindiv" id="Home">
        <div className="italy">
          <div className="hostmaindiv">
            <h1 className="heading1"> Be a Paladar Host</h1>
            <p className="para1">
              and travel without leaving your home! <br /> Meet people from all
              over the world, while earning on <br /> your schedule and sharing
              your culinary skills.
            </p>
            {/* button */}
            {/* <div className="hostbtns">
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <img className="hostbtnimg" src={buttonimg}></img>
              </button>
            </div> */}
            <div className="btnone hostbtns">
              <button
                data-aos="fade-left"
                style={{ background: "none", border: "none" }} onClick={() => window.open("https://play.google.com/store/apps/details?id=app.paladar.app")}
              >
                <img className="google_btn" src={google} />
              </button>
              <button
                data-aos="fade-left"
                class="ps-4"
                style={{ background: "none", border: "none" }} onClick={() => window.open("https://apps.apple.com/app/paladar/id1645135680")}
              >
                <img className="google_btn" src={apple} />
              </button>
            </div>
          </div>

          <div>
            <div style={{ margiTop: "30px" }}>
              <img className="cookimage" src={groups} />
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
}
