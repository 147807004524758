import React, { useEffect } from "react";
import "./Havana.scss";
import havana from "../Images/havana.svg";
import logo from "../Images/havlogo.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Havana = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="centre_hav" id="Hist">
      <div className="main_hav">
        <div data-aos="fade-right" className="hav_img">
          <img src={havana} />
        </div>

        <div data-aos="fade-left" className="hav_text">
          <div className="hav_title">The History of “Paladares”</div>

          <div className="hav_para">
            Paladar is the name used by Cuban for restaurants. For many years,
            the paladares were authentic underground restaurants where people
            served meals in their own homes, expecially to tourists who wanted
            to live a diferente experience and interact with the local
            population.
          </div>

          <div className="hav_logo_img">
            <img src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Havana;
