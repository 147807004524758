import React, { useEffect } from "react";
import "./Problem.scss";
// pics

import p404 from "../Images/404.svg";

import circle from "../Images/circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
const Problem = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div className="main_problem" id="Prob">
      <div className="problem_content">
        <div className="p_relate">
          <div data-aos="fade-left" className="p_title">
            The problem of “overtourism”
          </div>

          <div data-aos="fade-right" className="p_para">
            We have all felt on a trip the feeling of not really knowing the
            place. Without contact with the locals, without sharing some of
            their experiences, we can hardly claim knowledge of a culture and a
            place. The most touristic cities are so full of tourists that it is
            increasingly difficult to eat traditional food and get to know the
            local people and their culture. This desire for a more authentic
            tourist experience is more frequent than you think, it conects you
            with more travelers than you can imagine!
          </div>

          <img data-aos="zoom-out" className="p_img" src={p404} />
          <img className="bcircle" src={circle} />
        </div>
      </div>
    </div>
  );
};

export default Problem;
