import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import crod from "../Images/benefit.svg";
import "./Crowd.scss";
import hand from "../Images/hand.svg";
import caland from "../Images/calander.svg";
import men from "../Images/men.svg";
import face from "../Images/face.svg";
import grop from "../Images/group.svg";

import AOS from "aos";
export default function Crowd() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="maincontentdiv" id="Benefits">
      <Grid container spacing={2} className="maincant align_item_end">
        <Grid item xs={10} sm={6} style={{ textAlign: "end" }}>
          <div className="guysdiv">
            <img data-aos="zoom-out" className="guys" src={crod} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="mainof">
            <div className="crowdheading">
              <h1 data-aos="fade-left" className="mainheading">
                Benefits of being a Paladar Host
              </h1>
            </div>
            {/* boxx */}
            <div className="mainimages">
              {/* images */}
              <div className="boxmaindiv">
                <div data-aos="fade-left" className="boxdiv">
                  <div className="circle">
                    <img className="hand" src={hand} />
                  </div>
                  <div className="rectang">
                    <h2 className="rectanheading">Earn extra income.</h2>
                  </div>
                </div>

                <div data-aos="fade-left" className="boxdiv">
                  <div className="circle">
                    <img className="caland" src={caland} />
                  </div>
                  <div className="rectang">
                    <h2 className="rectanheading">Earn on your Schedule.</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* second container */}
      <Grid container>
        <Grid xs={2}></Grid>
        <Grid xs={8}>
          <div className="belowcartdiv">
            {/* 1 */}
            <div data-aos="fade-left" className="boxseconddiv">
              <div className="circle">
                <img className="men" src={men} />
              </div>
              <div className="rectang">
                <h2 className="rectanheading">
                  Meet people from all over the world.
                </h2>
              </div>
            </div>
            {/* 2 */}
            <div data-aos="fade-left" className="boxseconddiv">
              <div className="circle">
                <img className="face" src={face} />
              </div>
              <div className="rectang">
                <h2 className="rectanheading">
                  Improve and share your culinary skills.
                </h2>
              </div>
            </div>
            {/* 3 */}
            <div data-aos="fade-left" className="boxseconddiv">
              <div className="circle">
                <img className="grop" src={grop} />
              </div>
              <div className="rectang">
                <h2 className="rectanheading">
                  Never have a meal alone again.
                </h2>
              </div>
            </div>
          </div>
        </Grid>
        <Grid xs={2}></Grid>
      </Grid>

      {/* <div className="bollofcrowd">
        <img className="bollofcrowdimage" src={bolll} />
      </div> */}
    </div>
  );
}
