import React, { useEffect } from "react";
import "./Footer.scss";
import logo from "../../Container/Page1/Images/footerlogo.svg";
import google from "../../Container/Page1/Images/google.svg";
import apple from "../../Container/Page1/Images/apple.svg";
import flag from "../../Container/Page1/Images/flag.svg";

// mui
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Button } from "bootstrap";
import AOS from "aos";

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div className="main_footer">
      <div className="footer">
        <div data-aos="zoom-out" className="footer_title">
          <img style={{ cursor: "pointer" }} src={logo} />
        </div>

        <div className="footer_icon">
          <button
            data-aos="zoom-out"
            style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => window.open("https://www.facebook.com/profile.php?id=100085301058476")}
          >
            <FacebookIcon className="icon_color" />
          </button>
          <button
            data-aos="zoom-out"
            style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => window.open("https://www.instagram.com/paladar_app/")}
          >
            <InstagramIcon className="icon_color" />
          </button>
          <button
            data-aos="zoom-out"
            style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => window.open("https://www.linkedin.com/company/paladar-app/")}
          >
            <LinkedInIcon className="icon_color" />{" "}
          </button>
        </div>

        <div className="btnone">
          <button
            data-aos="fade-left"
            style={{ background: "none", border: "none" }} onClick={() => window.open("https://play.google.com/store/apps/details?id=app.paladar.app")}
          >
            <img className="google_btn" src={google} />
          </button>
          <button
            data-aos="fade-left"
            style={{ background: "none", border: "none" }} onClick={() => window.open("https://apps.apple.com/app/paladar/id1645135680")}
          >
            <img className="google_btn" src={apple} />
          </button>
        </div>

        <div className="line"></div>
        <div className="flag">
          <div className="flag_img">
            <img src={flag} />
          </div>
          <div>
            <div className="copyright">
              <CopyrightIcon /> Copyright 2022 Paladar All Rights Reserved
            </div>
            <button className="privacy-policy" onClick={() => window.open("https://www.iubenda.com/privacy-policy/42175534")}>
              Privacy Policy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
