import React from "react";
import "../Headerpage/Headerpage.scss";
import { Container, Row, Col } from "react-bootstrap";
import headerimg from "../../Page1/Images/header.png";
import headermbl from "../../../Components/Images/headermbl.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import google from "../../../Container/Page1/Images/google.svg";
import apple from "../../../Container/Page1/Images/apple.svg";
import buttonimg from "../Images/buttonimg.svg";
// import bolll from "../../Page2/Images/bolll.png";
export default function Headerpage() {
  return (
    <>
      <section className="flow" id="Home">
        <Container
          fluid
          style={{
            backgroundColor: "#FFF3E9",
          }}
          className="maintall"
        >
          <Row className="backpottimg">
            <Col
              className="make"
              lg={7}
              md={7}
              sm={12}
              style={{ padding: "0px" }}
            >
              <div>
                <div className="headerimgdiv">
                  <img className="headerimg" src={headerimg}></img>
                  <h1 className="partyheading">Eat in a Local's Home</h1>
                </div>
              </div>
              <div className="imageofbuttonsdiv">
                <div className="btnone hostbtns">
                  <button
                    data-aos="fade-left"
                    style={{ background: "none", border: "none" }} onClick={() => window.open("https://play.google.com/store/apps/details?id=app.paladar.app")}
                  >
                    <img className="google_btn" src={google} />
                  </button>
                  <button
                    data-aos="fade-left"
                    class="ps-4"
                    style={{ background: "none", border: "none" }} onClick={() => window.open("https://apps.apple.com/app/paladar/id1645135680")}
                  >
                    <img className="google_btn" src={apple} />
                  </button>
                </div>
                {/* <img className="imageofbuttons" src={buttonimg} /> */}
              </div>
            </Col>
            <Col lg={5} md={5} sm={12} style={{ position: "relative" }}>
              <div className="headermbldiv">
                <img className="headermbl" src={headermbl}></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
