import React from "react";
import "./Pagetwo.scss";
import Nvabarcook from "../Page2/Navbarcook/Navbarcook";
import Hostmain from "../Page2/Hostmain/Hostmain";
import Phone from "../Page2/Phone/Phone";
import Crowd from "../Page2/Crowd/Crowd";
import FAQ from "../Page2/FAQ/FAQ";
import Subsription from "../Page2/Subscription/Subscription";
import Footer from "../../Components/Footer/Footer";

const Pagetwo = () => {
  return (
    <div className="Pagetwo" id="page1">
      <Nvabarcook />
      <Hostmain />
      <Phone />
      <Crowd />
      <FAQ />
      <Subsription />
      <Footer />
    </div>
  );
};

export default Pagetwo;
