import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Pageone from "../src/Container/Page1/Pageone";
import Pagetwo from "../src/Container/Page2/Pagetwo";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Pageone />}></Route>
          <Route path="/host" exact element={<Pagetwo />}></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
