import React, { useEffect } from "react";
import phoneimg1 from "../Images/phone1.svg";
import phoneimg2 from "../Images/phone2.svg";
import phoneimg3 from "../Images/phone3.svg";
import phoneimg4 from "../Images/phone4.svg";
import phoneimg5 from "../Images/phone3.svg";
import digitnmbr1 from "../../Page1/Images/nmbr.svg";
import digitnmbr2 from "../../Page1/Images/nmbr2.svg";
import digitnmbr3 from "../../Page1/Images/nmbr3.svg";
import digitnmbr4 from "../../Page1/Images/nmbr4.svg";
import digitnmbr5 from "../../Page1/Images/nmbr5.svg";

import "../Phone/Phone.scss";
import AOS from "aos";
import Grid from "@mui/material/Grid";

export default function Phone() {
  const phoneData = [
    {
      para: "In your profile, select the option “become a  host” and fill in all the necessary information",
      picture: phoneimg1,
      digit: digitnmbr1,
    },
    {
      para: "In your Host profile, you must update all the information about your Paladar to make it more appealing",
      picture: phoneimg2,
      digit: digitnmbr2,
    },
    {
      para: "In the “my reservations” section of your host profile you will be able to see all the requests that have been maden to your Paladar",
      picture: phoneimg3,
      digit: digitnmbr3,
    },
    {
      para: "When you select one of the requests, a screen will appear with all the information of the request, including dietary restrictions.",
      picture: phoneimg4,
      digit: digitnmbr4,
    },
    {
      para: "After accepting the request, the reservation is confirmed and you will be able to find it in the “upcoming” section of the “my paladar reservations”",
      picture: phoneimg5,
      digit: digitnmbr5,
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 4000,
      offset: 120,
      easing: "ease", // default easing for AOS animations
      once: false,
    });
  }, []);

  return (
    <>
      <div className="phonemaindiv"   id="Work">
        {/* content */}
        <div className="phoneworksdiv">
          <h2
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
            className="phoneheading"
          >
            How it Works
          </h2>
          <p data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center" className="phonepara">
            The Host of a Paladar is free to set the price, the number of seats
            and the schedule of his Paladar. He may receive <br /> several
            requests for his Paladar, but the reservation will only be confirmed
            when he accepts one of them. There will be <br /> no commitment
            until then.
          </p>
        </div>
        {/* phone part */}
        <div className="phonepart">
          {phoneData.map((data, index) => {
            if (index % 2 === 0) {
              return (
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      data-aos="fade-left"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-mirror="true"
                      data-aos-once="false"
                      data-aos-anchor-placement="top-center"
                      className="paragraphphonediv1"
                    >
                      <p className="paraphone">{data.para}</p>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="marker">
                      <div className="markerheader">
                        <h2 className="markerhead">
                          {/* <span className="digit">{index + 1}</span> */}
                          <img
                            className="digitheadbelowimage"
                            src={data.digit}
                          />
                        </h2>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div data-aos="zoom-out" className="phoneareamaindiv">
                      <div className="phoneimage">
                        <img className="phonesize" src={data.picture}></img>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    xl={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div data-aos="zoom-out" className="phoneareamaindivsecond">
                      <div className="phoneimage">
                        <img className="phonesize" src={data.picture}></img>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={2} xl={4}>
                    <div className="marker">
                      <div>
                        <div className="markerheaderbelow">
                          <h2 className="markerheadbelow">
                            {/* <span className="digit"> {index + 1}</span> */}
                            <img
                              className="digitheadbelowimage"
                              src={data.digit}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    xl={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div data-aos="fade-right" className="paragraphphonediv2">
                      <p className="paraphonenmbr2">{data.para}</p>
                    </div>
                  </Grid>
                </Grid>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}
