import React from "react";
import logo from "../../Page1/Images/logo.svg";
import "./Navbarcook.scss";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbarcook = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="close" onClick={toggleDrawer(anchor, true)}>
        <CloseIcon className="icons" />
      </div>

      <div className="navbar__container">
        <a
          style={{ color: "black", textDecoration: "none" }}
          href="#Home"
        >
          <div className="items">Home</div></a>
        <a style={{ color: "black", textDecoration: "none" }} href="#Work">
          <div className="items">How it Works</div></a>
        <a
          style={{ color: "black", textDecoration: "none" }}
          href="#Benefits"
        >
          <div className="items">Benefits</div></a> <a style={{ color: "black", textDecoration: "none" }} href="#123">
          <div className="items">FAQ</div></a> <a
            style={{ color: "black", textDecoration: "none" }}
            href="#Subscription"
          >
          <div className="items">Subscription</div></a>
      </div>
    </Box>
  );

  return (
    <>
      <div className="main_navbar">
        <div className="nav_logo">
          <button
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <img src={logo} className="logo_img" />
          </button>
        </div>

        <div className="nav_text">
          <div>
            <div className="hover-underline-animation">
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="#Home"
              >
                Home
              </a>
            </div>
          </div>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#Work">
              How it Works
            </a>
          </div>
          <div className="hover-underline-animation">
            <a
              style={{ color: "black", textDecoration: "none" }}
              href="#Benefits"
            >
              Benefits
            </a>
          </div>
          <div className="hover-underline-animation">
            <a style={{ color: "black", textDecoration: "none" }} href="#123">
              FAQ
            </a>
          </div>
          <div className="hover-underline-animation">
            <a
              style={{ color: "black", textDecoration: "none" }}
              href="#Subscription"
            >
              Subscription
            </a>
          </div>
        </div>

        <div className="nav_btn">
          {" "}
          <a style={{ color: "white", textDecoration: "none" }} href="/">
            <button className="nav_button">Main Page</button>
          </a>
        </div>

        <div className="ham_nav">
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <div className="burger" onClick={toggleDrawer(anchor, true)}>
                <div id="menu" class="menu_color">
                  <MenuIcon />
                </div>
              </div>

              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default Navbarcook;
