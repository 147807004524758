import React from "react";
import "./Pageone.scss";
import Benefit from "./Benefit/Benefit";
import Footer from "../../Components/Footer/Footer";
import Havana from "./Havana/Havana";
import Navbar from "../Page1/Navbar/Navbar";
import Problem from "./Problem/Problem";
import Solution from "./Solution/Solution";
import FAQ from "./FAQ/FAQ";
import Subscription from "./Subscription/Subscription";
import Headerpage from "./Headerpage/Headerpage";
import Mobile from "./Mobile/Mobile";

const Pageone = () => {
  return (
    <div className="Pageone" id="page2">
      <Navbar />
      <Headerpage />

      <div className="hidden">
        <Problem />
        <Solution />
        <Havana />
        <Mobile />
        <Benefit />
        <FAQ />
        <Subscription />
        <Footer />
      </div>
    </div>
  );
};

export default Pageone;
