import React, { useEffect } from "react";
import "./Benefit.scss";
import bg from "../Images/benefit.svg";
import location from "../Images/location.svg";
import food from "../Images/food.svg";
import book from "../Images/book.svg";
import peopel from "../Images/peopel.svg";
import AOS from "aos";
import "aos/dist/aos.css";
const Benefit = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <div className="height">
        <div className="r_flex" id="Benf">
          <div className="main_benefit">
            <img data-aos="zoom-out" className="beneimg" src={bg} />

            <div className="for_need">
              <div data-aos="fade-left" className="bene_title">
                Benefits of using Paladar app
              </div>

              <div className="bene_card">
                <div data-aos="fade-left" className="card_1">
                  <div className="card_img">
                    <img className="loc" src={location} />
                  </div>

                  <div className="card_para">
                    You have the chance to meet the locals and they can give you
                    tips on the best places to visit.
                  </div>
                </div>

                <div data-aos="fade-left" className="card_1">
                  <div className="card_img">
                    <img className="loc" src={food} />
                  </div>

                  <div className="card_para">
                    You can try traditional food in their own homes, while
                    saving Money and escaping the routine of restaurants.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bene_card2">
            <div data-aos="fade-left" className="card_1">
              <div className="card_img">
                <img className="loc" src={book} />
              </div>

              <div className="card_para">
                You will learn more about the culture of the country where you
                are.
              </div>
            </div>

            <div data-aos="fade-left" className="card_1">
              <div className="card_img">
                <img className="loc" src={peopel} />
              </div>

              <div className="card_para">
                You can also meet more people like you if you decide to
                experience it with other travellers.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefit;
