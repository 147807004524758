import React, { useEffect } from "react";
import "../Mobile/Mobile.css";
import phoneimg1 from "../Images/shf1.svg";
import phoneimg2 from "../Images/shf2.svg";
import phoneimg3 from "../Images/shf3.svg";
import phoneimg4 from "../Images/shf4.svg";
import phoneimg5 from "../Images/shf5.svg";
import phoneimg6 from "../Images/shf6.svg";
import phoneimg7 from "../Images/shf7.svg";
import digitnmbr1 from "../Images/nmbr.svg";
import digitnmbr2 from "../Images/nmbr2.svg";
import digitnmbr3 from "../Images/nmbr3.svg";
import digitnmbr4 from "../Images/nmbr4.svg";
import digitnmbr5 from "../Images/nmbr5.svg";
import digitnmbr6 from "../Images/nmbr6.svg";
import digitnmbr7 from "../Images/nmbr7.svg";
import bolll from "../../Page1/Images/circle.svg";
import Grid from "@mui/material/Grid";
import AOS from "aos";
export default function Mobile() {
  const MobileData = [
    {
      para: "You select, on the main screen, a Paladar that you like",
      picture: phoneimg1,
      digit: digitnmbr1,
    },
    {
      para: "After viewing your information, click on the “send a request” button",
      picture: phoneimg2,
      digit: digitnmbr2,
    },
    {
      para: "Choose the day you want to book, if you haven't chosen it before.",
      picture: phoneimg3,
      digit: digitnmbr3,
    },
    {
      para: "You select the time that suits you best.",
      picture: phoneimg4,
      digit: digitnmbr4,
    },

    {
      para: "You confirm the request to this Host",
      picture: phoneimg5,
      digit: digitnmbr5,
    },
    {
      para: "Before confirming, you can change the information and choose to have with other travelers",
      picture: phoneimg6,
      digit: digitnmbr6,
    },
    {
      para: "You sent a request. Your reservation will be confirmed when the host accepts it. Send for inscrease your chances of having na amazing experience..",
      picture: phoneimg7,
      digit: digitnmbr7,
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  return (
    <>
      <div className="phonemaindiv" id="Work">
        {/* content */}
        <div className="phoneworksdiv">
          <h2 data-aos="fade-left" className="phoneheading">
            How it Works
          </h2>
          <p data-aos="fade-right" className="phonepara">
            You should make many requests for the same time to diferente
            Paladares! When the first one accepts, all <br /> others will be
            deleted. This way you will increase your chance of having a unique
            experience of having a meal <br /> at a local's home!
          </p>
        </div>
        {/* phone part */}
        <div className="phonepart">
          {MobileData.map((data, index) => {
            if (index % 2 === 0) {
              return (
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    xl={5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div data-aos="fade-right" className="paragraphphonediv1">
                      <p className="paraphone">{data.para}</p>
                    </div>
                  </Grid>
                  <Grid item xs={2} xl={2}>
                    <div className="marker">
                      <div className="digitheader">
                        <h2 className="digithead">
                          {/* <span className="digit">{index + 1}</span> */}
                          <img
                            className="digitheadbelowimage"
                            src={data.digit}
                          />
                        </h2>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={5} xl={5}>
                    <div data-aos="zoom-out" className="phoneareamaindiv">
                      <div className="phoneimage">
                        <img className="phonesize" src={data.picture}></img>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    xl={5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div data-aos="zoom-out" className="phoneareamaindivsecond">
                      <div className="phoneimage">
                        <img className="phonesize" src={data.picture}></img>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={2} xl={2}>
                    <div className="marker">
                      <div>
                        <div className="digitheaderbelow">
                          <h2 className="digitheadbelow">
                            <img
                              className="digitheadbelowimagebelow"
                              src={data.digit}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    xl={5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div data-aos="fade-left" className="paragraphphonediv2">
                      <p className="paraphonetwo">{data.para}</p>
                    </div>
                  </Grid>
                </Grid>
              );
            }
          })}
        </div>
      </div>

      <div className="bolllofdiv">
        <img src={bolll} />
      </div>
    </>
  );
}
