import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "aos/dist/aos.css";

ReactDOM.render(

    <App />
  ,
  document.getElementById('root')
);


